import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST


export const getSubCategories = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_SUBCATEGORIES_REQUEST" });
            const { data } = await axios.get(`${API_URL}/subcategory/admin`);
            dispatch({ type: "GET_SUBCATEGORIES_SUCCESS", subcategories: data });
        } catch (error) {
            dispatch({ type: "GET_SUBCATEGORIES_FAILURE", error: error.message });
        }
    };
}

export const addSubCategory = (subcategory) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "ADD_SUBCATEGORY_REQUEST" });
            const { data } = await axios.post(`${API_URL}/subcategory`, subcategory, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "ADD_SUBCATEGORY_SUCCESS",
                subcategory: data,
            });
            toast.success('Sub Category added successfully!');
        } catch (error) {
            dispatch({ type: "ADD_SUBCATEGORY_FAILURE", error: error.message });
        }
    };
}

export const deleteSubCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "DELETE_SUBCATEGORY_REQUEST" });
            await axios.delete(`${API_URL}/subcategory/${id}`, {
                headers: { "x-auth-token": `${localStorage.getItem("token")}` },
            });
            dispatch({ type: "DELETE_SUBCATEGORY_SUCCESS", id });
            toast.success('Sub Category deleted successfully!');
        } catch (error) {
            dispatch({ type: "DELETE_SUBCATEGORY_FAILURE", error: error.message });
        }
    };
}

export const updateSubCategory = (id, subcategory) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "UPDATE_SUBCATEGORY_REQUEST" });
            const { data } = await axios.put(`${API_URL}/subcategory/${id}`, subcategory, {
                headers: {
                    "x-auth-token": `${localStorage.getItem("token")}`,
                },
            });
            toast.success('Sub Category Updated successfully!');
            dispatch({ type: "UPDATE_SUBCATEGORY_SUCCESS", subcategory: data });
        } catch (error) {
            dispatch({ type: "UPDATE_SUBCATEGORY_FAILURE", error: error.message });
        }
    };
}
