import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

const CustomerPdf = () => {
  const { id } = useParams();
  const { userList } = useSelector((state) => state?.Auth);
  const user = userList.filter((user) => user?._id === id);
  const printRef = useRef();

  const handleLoading = () => {};
  return (
    <>
      <div className="content-wrapper container-fluid flex flex-column justify-content-start">
        <div className="mb-3 print_button">
          <ReactToPrint
            trigger={() => (
              <button onClick={handleLoading}>Print this out!</button>
            )}
            content={() => printRef.current}
            documentTitle={`${user[0].firstName}'s information`}
          />
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card mb-3 bg-white card_height" ref={printRef}>
              <div className="img_for_customer_print card-header d-flex justify-content-center ">
                <div className="d-flex justify-content-center">
                  <img
                    src={"/assets/img/logo.png"}
                    className="w-25 h-25 img-fluid "
                    alt=""
                  />
                </div>
              </div>
              <div className="card-body">
                <h2 className="card-title text-center my-5">
                  Customer Details
                </h2>
                <div className="row d-flex justify-content-center ">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-md-6 ">
                        <p>
                          <strong>First Name:</strong> {user[0].firstName}
                        </p>
                        <p>
                          <strong>Email:</strong> {user[0].email}
                        </p>
                        <p>
                          <strong>Business Type:</strong> {user[0].businessType}
                        </p>
                        <p>
                          <strong>Reg State:</strong> {user[0].regState}
                        </p>
                        <p>
                          <strong>In Business Since:</strong>{" "}
                          {user[0].inBusinessSince}
                        </p>
                        <p>
                          <strong>City:</strong> {user[0].city}
                        </p>
                        <p>
                          <strong>Zip Code:</strong> {user[0].zipCode}
                        </p>
                        <p>
                          <strong>Fed Tax Id:</strong> {user[0].fedTaxId}
                        </p>
                        <p>
                          <strong>JBT Id:</strong> {user[0].jbtId}
                        </p>
                        <p>
                          <strong>Customer Type:</strong> {user[0].type}
                        </p>

                        <p>
                          <strong>Physical Address:</strong>{" "}
                          {user[0].physicalAddress}
                        </p>
                      </div>
                      <div className="col-md-6 ">
                        <p>
                          <strong>Last Name:</strong> {user[0].lastName}
                        </p>
                        <p>
                          <strong>Tel Business:</strong> {user[0].telBusiness}
                        </p>
                        <p>
                          <strong>Company Name:</strong> {user[0].companyName}
                        </p>
                        <p>
                          <strong>Trade Name:</strong> {user[0].tradeName}
                        </p>
                        <p>
                          <strong>Shop Number:</strong> {user[0].shopNumber}
                        </p>
                        <p>
                          <strong>State:</strong> {user[0].state}
                        </p>
                        <p>
                          <strong>Resale Tax Id:</strong> {user[0].resaleTaxId}
                        </p>
                        <p>
                          <strong>Customer Status:</strong> {user[0].isApproved}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer my-5 img_for_customer_print margin_of_footer">
                <h1 className="text-center"> Concent</h1>
                <hr />
                <p className="p-3">
                  The undersigned, in both individual and corporate capacity,
                  warrants that the foregoing information provided is current
                  and accurate and authorizes AMERICA’S SILVER COLLECTION, LLC
                  (ASC) to check credit, employment history, bank and trade
                  references periodically. By signing this application, the
                  signor represents that he/she is a principal of a corporation,
                  a sole proprietor or a partner and agrees to be personally
                  liable to ASC for the unpaid debts of the
                  corporation/individually. And on behalf of all directors or
                  partners liable to carry appropriate insurance for the goods
                  purchase and should add ASC as beneficiary in policy. All past
                  due accounts accrue interest at the rate of 1.5% per month or
                  the maximum interest rate permitted by law both in prejudgment
                  and post judgment. The undersigned, in both individual and
                  corporate capacity, further agrees to pay all costs,
                  Collection Charges, NSF returned check fee, court costs and
                  attorney’s fees whether suit is filed or not. This instrument
                  is intended to be a security agreement and a financing
                  statement pursuant to the uniform commercial code. The amount
                  of indebtedness secured by this security agreement shall be
                  the amount due or to become due under invoices issued by ASC
                  for goods delivered to applicant. The undersigned or his
                  representative acknowledges that by signing for any delivery,
                  by whatever method, constitutes acceptance of the invoices
                  terms. . The undersigned agrees to a re-stocking charge equal
                  to 15% of the invoice purchase price on any merchandise
                  returned after thirty (30) days from date of purchase. Return
                  of merchandise revokes discount on retained merchandise, and
                  the return merchandise must be in original condition as bought
                  with all its accessories. The undersigned further represents
                  that he is not now in the US Military and will notify ASC, in
                  writing, if such status changes. All reference to gender is to
                  be construed as neutral. The undersigned agrees to notify
                  AMERICA’S SILVER COLLECTION, LLC of any change in name,
                  corporate, form or any material business change, and also
                  understand the company has reserved the right to reject any
                  application. By signing below the undersigned agreed to all
                  above term, conditions and polices.
                </p>
                <div className="col-md-10 ms-5  mt-3">
                  <p className="fw-bold">Customer's Signature</p>
                  <img
                    src={user[0].signature}
                    alt="signature"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerPdf;
