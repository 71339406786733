import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";
// import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateOrderStatus } from "../actions/orderActions";

const Order = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.Order.orders);
  const Loading = useSelector((state) => state.product?.loading);
  const { company } = useParams();
  const initialOrderStatusList = orders.map((order) => ({
    orderId: order._id,
    status: order.status,
  }));

  const [orderStatusList, setOrderStatusList] = useState(
    initialOrderStatusList
  );

  const handleStatus = (e, orderId) => {
    const { value } = e.target;
    setOrderStatusList((prevStatusList) => {
      const updatedStatusList = prevStatusList.map((status) => {
        if (status.orderId === orderId) {
          dispatch(updateOrderStatus(orderId, value));
          return {
            ...status,
            status: value,
          };
        }
        return status;
      });
      return updatedStatusList;
    });
  };

  const columns = [
    {
      name: "Order Number",
      selector: (row) => row.id,
      sortable: true,
      //   width: "70px",
    },
    {
      name: "Order Amount",
      selector: (row) => row.totalPrice,
      sortable: true,
    },
    {
      name: "Order Status",
      cell: (row) => {
        const orderStatus = orderStatusList.find(
          (status) => status.orderId === row._id
        );
        return (
          <select
            id={row._id}
            value={orderStatus ? orderStatus.status : ""}
            onChange={(e) => handleStatus(e, row._id)}
          >
            <option value="Processing">Processing</option>
            <option value="Shipped">Shipped</option>
            <option value="Received">Received</option>
          </select>
        );
      },
    },
    {
      name: "Order Details",
      cell: (row) => (
        <Link to={`/order-details/${row._id}`}>
          <button className="btn btn-primary">Details</button>
        </Link>
      ),
    },
  ];

  const data = orders
    .filter((item) => item.company === company)
    .map((item) => ({
      _id: item._id,
      id: item.id,
      totalPrice: item.itemsPrice,
      status: item.status,
    }));

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5">
            <div className="card">
              <div className="d-flex justify-content-between">
                <h5 className="card-header text-capitalize">All {company} Orders</h5>
              </div>
              <div className="table-responsive text-nowrap">
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortAsc="true"
                  pagination
                  responsive="true"
                />
              </div>
            </div>
          </div>
        )}

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default Order;
