import axios from "axios";
const API_URL = process.env.REACT_APP_API_HOST;

export const getCoupons = () => async (dispatch) => {
  try {
    dispatch({ type: "COUPONS_REQUEST" });

    const { data } = await axios.get(`${API_URL}/coupon/get`, {
      headers: {
        "x-auth-token": localStorage.getItem("xat"),
      },
    });
    dispatch({ type: "COUPONS_SUCCESS", coupons: data });
  } catch (error) {
    dispatch({
      type: "COUPONS_FAIL",
      error: error.response.data.message,
    });
  }
};

export const addCoupons = (Coupons) => async (dispatch) => {
  try {
    dispatch({ type: "COUPON_ADD_REQUEST" });

    const { data } = await axios.post(`${API_URL}/coupon/add`, Coupons, {
      headers: {
        "x-auth-token": localStorage.getItem("xat"),
      },
    });
    dispatch({ type: "COUPON_ADD_SUCCESS", coupons: data });
  } catch (error) {
    dispatch({
      type: "COUPON_ADD_FAIL",
      error: error.response.data.message,
    });
  }
};

export const updateCoupons = (id, Coupons) => async (dispatch) => {
  try {
    dispatch({ type: "COUPON_UPDATE_REQUEST" });

    const { data } = await axios.put(
      `${API_URL}/coupon/update/${id}`,
      Coupons,
      {
        headers: {
          "x-auth-token": localStorage.getItem("xat"),
        },
      }
    );
    dispatch({ type: "COUPON_UPDATE_SUCCESS", coupons: data });
  } catch (error) {
    dispatch({
      type: "COUPON_UPDATE_FAIL",
      error: error.response.data.message,
    });
  }
};

export const deleteCoupons = (id) => async (dispatch) => {
  try {
    dispatch({ type: "COUPON_DELETE_REQUEST" });

    const { data } = await axios.delete(`${API_URL}/coupon/delete/${id}`, {
      headers: {
        "x-auth-token": localStorage.getItem("xat"),
      },
    });
    dispatch({ type: "COUPON_DELETE_SUCCESS", coupons: data });
  } catch (error) {
    dispatch({
      type: "COUPON_DELETE_FAIL",
      error: error.response.data.message,
    });
  }
};
