import { React } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';

import { useSelector, useDispatch } from 'react-redux';

const { deleteTag } = require('../actions/tagActions');

const Tag = () => {

    const dispatch = useDispatch();
    const { tags } = useSelector(state => state.Tag);
    const Loading = useSelector(state => state.Tag.loading);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure?')) {
            dispatch(deleteTag(id));
        }
    }


    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,

        },
        {
            name: 'Action',
            cell: row => <div className="dropdown">
                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <div className="dropdown-menu">
                    <Link className="dropdown-item" to={{
                        pathname: `/tag/edit-tag/${row.id}`
                    }}><i className="bx bx-edit-alt me-1"></i> Edit</Link>
                    <div className="dropdown-item" onClick={() => { handleDelete(row.tagId) }} ><i className="bx bx-trash me-1"></i> Delete</div>
                </div>
            </div>,

        },
        {
            name: 'Tag Name',
            selector: row => row.tagName,
            sortable: true,
        },
        {
            name: 'Retailer Value',
            selector: row => row.retailerValue,
        },
        {
            name: 'Dropshipper Value',
            selector: row => row.dropshipperValue,
        },

    ];

    const data = tags.map(tag => {
        return {
            tagId: tag._id,
            id: tag.id,
            tagName: tag.tagName,
            retailerValue: tag.retailerValue,
            dropshipperValue: tag.dropshipperValue,
        }
    })

    return (
        <>
            <div className="content-wrapper container-fluid">
                {
                    Loading ? (<PulseLoader />) : (
                        <>
                            <div className="row px-5 py-3">
                                <div className="card">
                                    <div className='d-flex justify-content-between'>
                                        <h5 className="card-header">Retailer Tags</h5>
                                        <div>
                                            <Link to='/tag/add-tag' className="btn btn-outline-primary my-3">
                                                <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add Tag
                                            </Link>

                                        </div>
                                    </div>
                                    <div className="table-responsive text-nowrap">
                                        <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }

                <div className="content-backdrop fade"></div>
            </div>
        </>
    )
}

export default Tag