import { React } from 'react'
import DataTable from 'react-data-table-component';
import PulseLoader from '../components/PulseLoader';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userDelete } from '../actions/authActions';


const Customers = ({ type }) => {

  const dispatch = useDispatch();
  const { userList } = useSelector(state => state.Auth);
  const Loading = useSelector(state => state.Auth.loading);


  const handleDeleteUser = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(userDelete(id));
    }
  }

  const ApprovedUsers = userList.filter(user => user.isApproved !== 'Pending');
  const data = ApprovedUsers.map(allUserList => {
    return {
      userId: allUserList._id,
      id: allUserList.id,
      name: allUserList.firstName,
      type: allUserList.type,
      tier: allUserList.tier,
      isApproved: allUserList.isApproved
    }
  })

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      width: '70px',
    },
    {
      name: 'Action',
      cell: row => <div className="dropdown">
        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bx bx-dots-vertical-rounded"></i>
        </button>
        <div className="dropdown-menu">
          <Link className="dropdown-item" to={{
            pathname: `/customer/downloadinfo/${row.userId}`
          }}><i className="bx bx-download me-1"></i>Export</Link>
          <Link className="dropdown-item" to={{
            pathname: `/customers/approve-cusotmer/${row.userId}`
          }}><i className="bx bx-edit-alt me-1"></i>Edit</Link>
          <div className="dropdown-item" onClick={() => { handleDeleteUser(row.userId) }} ><i className="bx bx-trash me-1"></i>Delete</div>
        </div>
      </div>,
      width: '100px',
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row.type,
    },
    {
      name: 'Tier',
      selector: row => row.tier,
      cell: row => {
        return row.tier !== undefined ? (
          row.tier.tierName
        ) : (<>No Tier Assigned</>);
      }
    },
    {
      name: 'Status',
      selector: row => row.isApproved,
      sortable: true,
      cell: row => {
        return row.isApproved === 'Approved' ? (
          <span className="badge bg-success">{row.isApproved}</span>
        ) : (
          row.isApproved === 'Rejected' ? (
            <span className="badge bg-danger">{row.isApproved}</span>
          ) : (
            <span className="badge bg-dark">{row.isApproved}</span>
          )
        );
      },
    },


  ];

  return (
    <>
      <div className="content-wrapper container-fluid">
        {
          Loading ? (<PulseLoader />) : (
            <div className="row p-5">
              <div className="card">
                <div className='d-flex justify-content-between'>
                  <h5 className="card-header">All {type}</h5>
                  <div>
                    {/* <Link to='/cutomer/add-customer' className="btn btn-outline-primary my-3">
                      <span className="tf-icons bx bx-plus-circle"></span>&nbsp; Add {type}
                    </Link> */}

                  </div>
                </div>
                <div className="table-responsive text-nowrap">
                  <DataTable columns={columns} data={data} defaultSortAsc='true' pagination responsive='true' />
                </div>
              </div>
            </div>
          )
        }

        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default Customers