import axios from 'axios';
import { toast } from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_HOST
export const loginUser = (email, password) => {
    return {
        type: 'LOGIN',
        payload: {
            email,
            password
        }
    }
}

export const logoutUser = () => {
    return {
        type: 'LOGOUT'
    }
}

export function getProducts() {
    return async dispatch => {
        try {
            dispatch({ type: 'GET_PRODUCTS_REQUEST' });
            const { data } = await axios.get(`${API_URL}/products/adminproducts`);
            dispatch({ type: 'GET_PRODUCTS_SUCCESS', products: data});
        } catch (error) {
            dispatch({ type: 'GET_PRODUCTS_FAILURE', error: error.message });
        }
    }
}

export function addProduct(product) {
    return async dispatch => {
        try {
            dispatch({ type: 'ADD_PRODUCT_REQUEST' });
            const { data } = await axios.post(`${API_URL}/products/create`, product,
                {
                    headers: {
                        'x-auth-token': `${localStorage.getItem('token')}`,
                    }
                });
            dispatch({ type: 'ADD_PRODUCT_SUCCESS', product: data });
            toast.success('Product added successfully');
            return data;
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'ADD_PRODUCT_FAILURE', error: error.message });
        }
    }
}

export function setRedirecting() {
    return async dispatch => {
        dispatch({ type: 'SET_REDIRECTING' });
    }
}

export function deleteProduct(id) {
    return async dispatch => {
        try {
            dispatch({ type: 'DELETE_PRODUCT_REQUEST' });
            await axios.delete(`${API_URL}/products/delete/${id}`,
                { headers: { 'x-auth-token': `${localStorage.getItem('token')}` } });
            dispatch({ type: 'DELETE_PRODUCT_SUCCESS', id });
            toast.success('Product deleted successfully');
        } catch (error) {
            dispatch({ type: 'DELETE_PRODUCT_FAILURE', error: error.message });
        }
    }
}

export function updateProduct(id, product) {
    return async dispatch => {
        try {
            dispatch({ type: 'UPDATE_PRODUCT_REQUEST' });
            const { data } = await axios.put(`${API_URL}/products/update/${id}`, product,
            {
                headers: {
                    'x-auth-token': `${localStorage.getItem('token')}`,
                }
            });
            dispatch({ type: 'UPDATE_PRODUCT_SUCCESS', product: data.updatedProduct });
            toast.success('Product updated successfully');
        } catch (error) {
            dispatch({ type: 'UPDATE_PRODUCT_FAILURE', error: error.message });
        }
    }
}


export const loadProducts = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'LOAD_PRODUCTS_REQUEST' });
            dispatch({ type: 'LOAD_PRODUCTS_SUCCESS', products: data });
        } catch (error) {
            dispatch({ type: 'LOAD_PRODUCTS_FAILURE', error: error.message });
        }
    }
}