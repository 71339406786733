import React from 'react'
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const ProtectedRoute = ({ user, children }) => {

    const { isAuthenticated } = useSelector(state => state.Auth);
    
    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }
    
    return children;
}

export default ProtectedRoute