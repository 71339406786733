import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const OrderDeatil = () => {
  const { id } = useParams();
  const orders = useSelector((state) => state.Order.orders);
  const order = orders.find((order) => order._id === id);

  console.log(order)

  return (
    <>
      <div className="content-wrapper container-fluid">
        <div className="card mb-3">
          <div className="card-body">
            <h2 className="card-title">Order Details</h2>
            <div className="row">
              <div className="col-md-6">
                <p>
                  <b>Name : </b> {order?.shippingInfo?.name}
                </p>
                <p>
                  <b>Email : </b> {order?.shippingInfo?.email}
                </p>
                <p>
                  <b>Phone : </b> {order?.shippingInfo?.phone}
                </p>
                <p>
                  <b>City : </b> {order?.shippingInfo?.city}
                </p>
                <p>
                  <b>Country : </b> {order?.shippingInfo?.country}
                </p>
                <p>
                  <b>Zip Code : </b> {order?.shippingInfo?.zip}
                </p>
                <p>
                  <b>Address : </b> {order?.shippingInfo?.address}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>Payment Method : </b> {order?.paymentMethod}
                </p>
                <p>
                  <b>Item Price : </b> {order.itemsPrice}
                </p>
                <p>
                  <b>Shipping Price : </b> {order.shippingPrice}
                </p>
                <p>
                  <b>Total Price : </b> {order.itemsPrice}
                </p>
                <p>
                  <b>Payment Status : </b>
                  {order.isPaid === true ? "Paid" : "Pending"}
                </p>
                <p>
                  <b>Order Status : </b>
                  {order.status}
                </p>
                <p>
                  <b>Order Note : </b> {order.note}
                </p>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {order.orderItems.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <img
                            src={item.image}
                            alt={item.name}
                            style={{ width: "100px", height: "100px" }}

                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.qty}</td>
                        <td>{item.price}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDeatil;
