const subCategory = {
    subCategories: [],
    loading: false,
    error: null,
}


const SubCategory = (state = subCategory, action) => {
    switch (action.type) {
        case "GET_SUBCATEGORIES_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "GET_SUBCATEGORIES_SUCCESS":
            return {
                ...state,
                loading: false,
                subCategories: action.subcategories,
            };
        case "GET_SUBCATEGORIES_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "ADD_SUBCATEGORY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "ADD_SUBCATEGORY_SUCCESS":
            return {
                ...state,
                loading: false,
                subCategories: [...state.subCategories, action.subcategory],
            };
        case "ADD_SUBCATEGORY_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "DELETE_SUBCATEGORY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_SUBCATEGORY_SUCCESS":
            return {
                ...state,
                loading: false,
                subCategories: state.subCategories.filter(
                    (subCategory) => subCategory._id !== action.id
                ),
            };
        case "DELETE_SUBCATEGORY_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case "UPDATE_SUBCATEGORY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_SUBCATEGORY_SUCCESS":
            return {
                ...state,
                loading: false,
                subCategories: state.subCategories.map((subCategory) => subCategory._id === action.subcategory._id ? action.subcategory : subCategory),
            };
        case "UPDATE_SUBCATEGORY_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default SubCategory;