import React from 'react'
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineController,
  LineElement,
} from "chart.js";
// Doughnut, PolarArea, Radar,
import { Bar, Pie, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  RadialLinearScale,
  PointElement,
  LineElement
);

const Dashboard = () => {
  const { products } = useSelector(state => state.Product);
  const { orders } = useSelector(state => state.Order);
  const { categories } = useSelector(state => state.Category);
  const { userList } = useSelector(state => state.Auth);
  const productlengthcategory = categories.map(cat => {
    return {
      name: cat.name,
      length: products.filter(prod => prod.category._id === cat._id).length
    }
  })

  const monthwiseorders = orders.filter
    (order => order.company === "asc925")
    .map(order => {
      return {
        month: new Date(order.createdAt).getMonth(),
        year: new Date(order.createdAt).getFullYear(),
        total: order.totalPrice
      }
    })

  const getOrdersLength = (month, year) => {
    return monthwiseorders.filter(order => order.month === month && order.year === year).length
  }

  const dataforProducts = {
    labels: categories.map(cat => cat.name),
    datasets: [
      {
        label: "Products",
        data: productlengthcategory.map(cat => cat.length),
        backgroundColor: "#696cff",
        borderColor: "#696cff",
        borderWidth: 1,
      },
    ],
  }

  const pastSixMonths = new Array(6).fill(0).map((_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date.toLocaleString("default", { month: "long" });
  }).reverse();


  const dataLineChart = {
    labels: pastSixMonths,
    datasets: [
      {
        label: "Orders",
        data: new Array(7).fill(0).map((_, i) => getOrdersLength(i + new Date().getMonth() - 5, 2023)),
        fill: false,
        borderColor: "#696cff",
        backgroundColor: "#696cff",
        tension: 0.1,
      },
    ],
  };

  const optionsLineChart = {
    scales: {
      y: {
        beginAtZero: true,
      },

    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const dataPieChart = {
    labels: ["Blocked", "Pending", "Approved", "Rejected"],
    datasets: [
      {
        label: "Users",
        data: [],
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0"],
        hoverOffset: 4,
      },
    ],
  };


  const counts = userList.reduce((acc, user) => {
    const status = user.isApproved;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  dataPieChart.datasets[0].data = [
    counts["Blocked"] || 0,
    counts["Pending"] || 0,
    counts["Approved"] || 0,
    counts["Rejected"] || 0,
  ];



  const optionsPieChart = {
    plugins: {
      legend: {
        // display: false,
      },
    },
  };

  const userDataAccordingToStatus = userList.map(user => {
    return {
      status: user.isApproved,
      id: user.id
    }
  })

  console.log(userDataAccordingToStatus)



  return (
    <div className="content-wrapper container-fluid" >
      <div className="row">
        <h3 className='mb-3'>Asc925 Analytics</h3>

        <div className="col-md-6 mb-4">
          <div className='card p-5'>
            <h3 className="text-center">Orders History</h3>
            <div className="card-body">
              <Line options={optionsLineChart} data={dataLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className='card p-5'>
            <h3 className="text-center">Products by Categories</h3>
            <div className="card-body">
              <Bar data={dataforProducts} options={optionsLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-5">
          <div className='card p-5'>
            <h3 className="text-center">Customers</h3>
            <div className="card-body">
              <Pie data={dataPieChart} options={optionsPieChart} />
            </div>
          </div>
        </div>
        <div className="col-md-8"></div>
        {/* <div className="col-md-4 my-5">
          <Doughnut data={data} options={options} />
        </div> */}
        {/* <div className="col-md-4 my-5">
          <PolarArea data={data} />
        </div> */}
        {/* <div className="col-md-4 my-5">
          <Radar data={data} />
        </div>
        <div className="col-md-4 my-5">
          <Line options={optionsLineChart} data={dataLineChart} />
        </div> */}
      </div>
    </div >
  )
}

export default Dashboard