import { React, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { getCoupons } from "../../actions/couponActions";
const { getCategories } = require("../../actions/categoryActions");
const { getProducts } = require("../../actions/productActions");
const { getAllOrders } = require("../../actions/orderActions");
const { userGetAll, userLogout } = require("../../actions/authActions");
const { getTiers } = require("../../actions/tierActions");
const { getSubCategories } = require("../../actions/subCategoryActions");
const { getTags } = require("../../actions/tagActions");

const Sidebar = () => {
  const dispatch = useDispatch();
  const arrow = useRef([]);
  const [subMenuIndex, setSubMenuIndex] = useState(-1);
  const { isAuthenticated } = useSelector((state) => state.Auth);

  const handleMenuClick = (index) => {
    if (subMenuIndex === index) {
      setSubMenuIndex(-1);
    } else {
      setSubMenuIndex(index);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCategories());
      dispatch(getProducts());
      dispatch(getAllOrders());
      dispatch(userGetAll());
      dispatch(getTiers());
      dispatch(getSubCategories());
      dispatch(getTags());
      dispatch(getCoupons());
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
  const currentUrl = window.location.pathname;

  // const token = localStorage.getItem("xat");

  // const checkTokenExpiration = () => {
  //     if (token) {
  //         const base64Url = token.split(".")[1];
  //         const base64 = base64Url.replace("-", "+").replace("_", "/");
  //         const decodedData = JSON.parse(window.atob(base64));
  //         const verifyExp = decodedData.exp * 1000;
  //         const tokenVerify = verifyExp < Date.now();
  //         setData(decodedData);
  //         if (tokenVerify) {
  //             dispatch(userLogout());
  //             window.localStorage.clear();
  //             toast.error("Session expired, please login again to continue!");
  //         }
  //     } else {
  //         dispatch(userLogout());
  //         window.localStorage.clear();
  //     }
  // };

  return (
    <>
      <Navbar />
      <div className="nav" id="navbar">
        <nav className="nav__container">
          <div>
            <Link to="/" className="nav__link nav__logo">
              <i className="bx bxs-disc nav__icon"></i>
              <span className="nav__logo-name">Wholesale</span>
            </Link>

            <div className="nav__list">
              <div className="nav__items">
                <h3 className="nav__subtitle">Profile</h3>

                <Link
                  to="/"
                  className={
                    currentUrl === "/" ? "nav__link active" : "nav__link"
                  }
                >
                  <i className="bx bx-home nav__icon"></i>
                  <span className="nav__name">Dashboard</span>
                </Link>

                <div
                  className={
                    subMenuIndex === 1
                      ? "nav__dropdown dropdown_active"
                      : "nav__dropdown"
                  }
                  ref={(el) => (arrow.current[1] = el)}
                  onClick={() => handleMenuClick(1)}
                >
                  <div className="nav__link">
                    <i className="bx bx-category nav__icon"></i>
                    <span className="nav__name">Category</span>
                    <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                  </div>

                  <div className="nav__dropdown-collapse">
                    <div className="nav__dropdown-content">
                      <Link to="/categories" className="nav__dropdown-item">
                        Categories
                      </Link>
                      <Link to="/sub-categories" className="nav__dropdown-item">
                        Sub Categories
                      </Link>
                    </div>
                  </div>
                </div>

                <Link
                  to="/tier"
                  className={
                    currentUrl === "/tier" ? "nav__link active" : "nav__link"
                  }
                >
                  <i className="bx bx-trending-up nav__icon"></i>
                  <span className="nav__name">Tier</span>
                </Link>

                <Link
                  to="/tag"
                  className={
                    currentUrl === "/tag" ? "nav__link active" : "nav__link"
                  }
                >
                  <i className="bx bx-tag nav__icon"></i>
                  <span className="nav__name">Tag</span>
                </Link>

                <Link
                  to="/products"
                  className={
                    currentUrl === "/products"
                      ? "nav__link active"
                      : "nav__link"
                  }
                >
                  <i className="bx bx-store-alt nav__icon"></i>
                  <span className="nav__name">Products </span>
                </Link>

                <div
                  className={
                    subMenuIndex === 3
                      ? "nav__dropdown dropdown_active"
                      : "nav__dropdown"
                  }
                  ref={(el) => (arrow.current[3] = el)}
                  onClick={() => handleMenuClick(3)}
                >
                  <div className="nav__link">
                    <i className="bx bxs-purchase-tag-alt nav__icon"></i>

                    <span className="nav__name">Orders</span>
                    <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                  </div>

                  <div className="nav__dropdown-collapse">
                    <div className="nav__dropdown-content">
                      <Link to="/orders/asc925" className="nav__dropdown-item">
                        ASC925
                      </Link>
                      <Link
                        to="/orders/shopyblue"
                        className="nav__dropdown-item"
                      >
                        Shopy Blue
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    subMenuIndex === 2
                      ? "nav__dropdown dropdown_active"
                      : "nav__dropdown"
                  }
                  ref={(el) => (arrow.current[2] = el)}
                  onClick={() => handleMenuClick(2)}
                >
                  <div className="nav__link">
                    <i className="bx bx-user nav__icon"></i>
                    <span className="nav__name">Customer</span>
                    <i className="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                  </div>

                  <div className="nav__dropdown-collapse">
                    <div className="nav__dropdown-content">
                      <Link to="/customers" className="nav__dropdown-item">
                        Customers
                      </Link>
                      <Link
                        to="/customer/requests"
                        className="nav__dropdown-item"
                      >
                        Pending Request
                      </Link>
                      <Link
                        to="/customer/existing-customer"
                        className="nav__dropdown-item"
                      >
                        Existing Customer
                      </Link>
                    </div>
                  </div>
                </div>

                {/* <Link
                  to="/coupons"
                  className={
                    currentUrl === "/coupons" ? "nav__link active" : "nav__link"
                  }
                >
                  <i className="bx bxs-coupon nav__icon"></i>
                  <span className="nav__name">Coupons </span>
                </Link> */}

                {/* <div className="nav__dropdown">
                                <Link to="/product" className="nav__link">
                                    <i className='bx bx-store-alt nav__icon' ></i>
                                    <span className="nav__name">Products</span>
                                    <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                </Link>

                                <div className="nav__dropdown-collapse">
                                    <div className="nav__dropdown-content">
                                        <Link to="/product" className="nav__dropdown-item">All Products</Link>
                                        <Link to="/on-sale" className="nav__dropdown-item">On Sale</Link>
                                        <Link to="/featured-products" className="nav__dropdown-item">Featured</Link>
                                    </div>
                                </div>
                            </div>
                            <Link to="/payments" className="nav__link">
                                <i className='bx bx-dollar nav__icon' ></i>
                                <span className="nav__name">Payments</span>
                            </Link>
                            <Link to="/shippingcost" className="nav__link">
                                <i className='bx bx-calculator nav__icon' ></i>
                                <span className="nav__name">Shipping Cost</span>
                            </Link>
                            <div className="nav__dropdown">
                                <div className="nav__link">
                                    <i className='bx bx-file nav__icon' ></i>
                                    <span className="nav__name">Reports</span>
                                    <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                </div>

                                <div className="nav__dropdown-collapse">
                                    <div className="nav__dropdown-content">
                                        <Link to="/reports/order-report" className="nav__dropdown-item">Order Report</Link>
                                        <Link to="/" className="nav__dropdown-item">Customer Details </Link>
                                        <Link to="/" className="nav__dropdown-item">Dropship Customer Profit</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="nav__dropdown">
                                <Link to="/dropship-pending" className="nav__link">
                                    <i className='bx bx-menu nav__icon' ></i>
                                    <span className="nav__name">DS Profit</span>
                                    <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                </Link>

                                <div className="nav__dropdown-collapse">
                                    <div className="nav__dropdown-content">
                                        <Link to="/dropship-pending" className="nav__dropdown-item">Pending Profit</Link>
                                        <Link to="/dropship-paid" className="nav__dropdown-item">Paid Profit</Link>
                                    </div>
                                </div>
                            </div> */}
              </div>

              {/* <div className="nav__items">
                            <h3 className="nav__subtitle">Orders</h3>
    
                            <div className="nav__dropdown">
                                <Link to="/wholesale-orders" className="nav__link">
                                    <i className='bx bx-task nav__icon' ></i>
                                    <span className="nav__name">Wholesale</span>
                                    <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                </Link>

                                <div className="nav__dropdown-collapse">
                                    <div className="nav__dropdown-content">
                                        <Link to="/" className="nav__dropdown-item">Return Orders</Link>
                                        <Link to="/" className="nav__dropdown-item">Pending Orders</Link>
                                    </div>
                                </div>

                            </div>
                            <div className="nav__dropdown">
                                <Link to="/dropship-orders" className="nav__link">
                                    <i className='bx bx-task nav__icon' ></i>
                                    <span className="nav__name">Dropship</span>
                                    <i className='bx bx-chevron-down nav__icon nav__dropdown-icon'></i>
                                </Link>

                                <div className="nav__dropdown-collapse">
                                    <div className="nav__dropdown-content">
                                        <Link to="/" className="nav__dropdown-item">Return Orders</Link>
                                        <Link to="/" className="nav__dropdown-item">Pending Orders</Link>

                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>

          <div
            className="nav__link nav__logout"
            onClick={() => dispatch(userLogout())}
          >
            <i className="bx bx-log-out nav__icon"></i>
            <span className="nav__name">Log Out</span>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
