import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const { addTag, updateTag } = require('../actions/tagActions');

const TagForm = (props) => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;

  const { loading, tags } = useSelector(state => state.Tag);
  const [tie, setTie] = useState({});
  const [Tag, setTag] = useState({
    tagName: '',
    retailerValue: '',
    dropshipperValue: ''
  });

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      var updatedTag = tags.find(tag => tag.id == id);
      if (updatedTag) {
        setTie(updatedTag);
        setTag({
          tagName: updatedTag.tagName,
          retailerValue: updatedTag.retailerValue,
          dropshipperValue: updatedTag.dropshipperValue
        });
      }
    }

  }, [id, tags]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTag({ ...Tag, [name]: value });
  };


  const handleAddTag = (e) => {
    e.preventDefault();
    if (Tag.tagName === '') {
      toast.error('Please enter Tag name');
      return;
    } else if (Tag.retailerValue === '') {
      toast.error('Please enter retailerValue');
      return;
    } else if (Tag.dropshipperValue === '') {
      toast.error('Please enter dropshipperValue');
      return;
    } else if (Tag.retailerValue !== '' && Tag.dropshipperValue !== '' && Tag.tagName !== '') {
      dispatch(addTag(Tag));
      redirect('/tag')
    }
  }

  const handleUpdateTag = (e) => {
    e.preventDefault();
    dispatch(updateTag(tie._id, Tag));
    redirect('/tag');

  }

  return (
    <>
      <div className="content-wrapper container-fluid">
        {
          loading ? (<PulseLoader />) : (
            <div className="row p-5 justify-content-center">
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{FormName}</h5>
                  </div>
                  <div className="card-body">
                    <form  >
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Tier Name" name='tagName' value={Tag.tagName} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Number for RetailerValue" name='retailerValue' value={Tag.retailerValue} onChange={handleInputChange} required={true} type='number' placeholder="Like 5" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Number for DropshipperValue" name='dropshipperValue' value={Tag.dropshipperValue} onChange={handleInputChange} required={true} type='number' placeholder="Like 7" />
                          </div>
                        </div>
                      </div>

                      {
                        Editable === 'true' ? <button type="submit" onClick={handleUpdateTag} className="btn btn-primary">
                          Update Tag
                        </button> : <button type="submit" onClick={handleAddTag} className="btn btn-primary">
                          Save
                        </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default TagForm