const tag = {
    tags: [],
    message: '',
    loading: false,
    error: null,
}


const Tag = (state = tag, action) => {
    switch (action.type) {
        case 'GET_TAGS_REQUEST':
            return { ...state, loading: true };
        case 'GET_TAGS_SUCCESS':
            return { ...state, loading: false, tags: action.tags };
        case 'GET_TAGS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ADD_TAG_REQUEST':
            return { ...state, loading: true };
        case 'ADD_TAG_SUCCESS':
            return { ...state, loading: false, tags: [...state.tags, action.tags] };
        case 'ADD_TAG_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'DELETE_TAG_REQUEST':
            return { ...state, loading: true };
        case 'DELETE_TAG_SUCCESS':
            return { ...state, loading: false, tags: state.tags.filter(tag => tag._id !== action.id) };
        case 'DELETE_TAG_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'UPDATE_TAG_REQUEST':
            return { ...state, loading: true };
        case 'UPDATE_TAG_SUCCESS':
            return { ...state, loading: false, tags: state.tags.map(tag => tag.id === action.tags.id ? action.tags : tag) };
        case 'UPDATE_TAG_FAILURE':
            return { ...state, loading: false, error: action.error };

        default: return state;
    }
}

export default Tag;