import { React } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";
import { useSelector } from "react-redux";

const ExistingCustomer = ({ type }) => {
  const { userList } = useSelector((state) => state.Auth);
  const Loading = useSelector((state) => state.Auth.loading);

  const customerRequests = userList.filter(
    (user) => user.isApproved === "Pending" && user.existingCustomer === true
  );

  const data = customerRequests.map((allUserList) => {
    return {
      userId: allUserList._id,
      id: allUserList.id,
      name: allUserList.firstName,
      type: allUserList.type,
    };
  });

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      // width: '70px'
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          className="dropdown-item"
          to={{
            pathname: `/customers/approve-cusotmer/${row.userId}`,
          }}
        >
          <i className="bx bx-edit-alt me-1"></i>Change Status
        </Link>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
  ];

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5">
            <div className="card">
              <div className="d-flex justify-content-between">
                <h5 className="card-header">Pending {type}</h5>
                <div></div>
              </div>
              <div className="table-responsive text-nowrap">
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortAsc="true"
                  pagination
                  responsive="true"
                />
              </div>
            </div>
          </div>
        )}

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default ExistingCustomer;
