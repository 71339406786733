import { combineReducers } from "redux";
import Auth from "./authReducers";
import Product from "./productReducers";
import Category from "./categoryReducers";
import Tag from "./tagReducers";
import loadingReducer from "./loadingReducer";
import Order from "./orderReducers";
import Tier from "./tierReducers";
import SubCategory from "./subCategoryReducers";
import Coupons from "./couponReducers";
const rootReducer = combineReducers({
  Auth,
  Product,
  Category,
  Tag,
  loadingReducer,
  Order,
  Tier,
  SubCategory,
  Coupons,
});

export default rootReducer;
