import axios from "axios";
import { toast } from 'react-hot-toast';
const API_URL = process.env.REACT_APP_API_HOST

export const getTiers = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "GET_TIERS_REQUEST" });
            const { data } = await axios.get(`${API_URL}/tiers`);
            dispatch({ type: "GET_TIERS_SUCCESS", tiers: data });
        } catch (error) {
            dispatch({ type: "GET_TIERS_FAILURE", error: error.message });
        }
    };
}

export const addTier = (tier) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ADD_TIER_REQUEST' });
            const { data } = await axios.post(`${API_URL}/tiers`, tier,
                { headers: { 'x-auth-token': `${localStorage.getItem('token')}` } });
                dispatch({ type: 'ADD_TIER_SUCCESS', tier: data });
                toast.success("Tier added successfully");
        } catch (error) {
            dispatch({ type: 'ADD_TIER_FAILURE', error: error.message });
        }
    }
}

export const deleteTier = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'DELETE_TIER_REQUEST' });
            await axios.delete(`${API_URL}/tiers/${id}`,
                { headers: { 'x-auth-token': `${localStorage.getItem('token')}` } });
            dispatch({ type: 'DELETE_TIER_SUCCESS', id });
            toast.success('Tier deletd successfully!');
        }
        catch (error) {
            dispatch({ type: 'DELETE_TIER_FAILURE', error: error.message });
        }
    }
}

export const updateTier = (id, tier) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_TIER_REQUEST' });
            const { data } = await axios.put(`${API_URL}/tiers/${id}`, tier,
                { headers: { 'x-auth-token': `${localStorage.getItem('token')}` } });
            dispatch({ type: 'UPDATE_TIER_SUCCESS', tier: data });
            toast.success('Tier updated successfully!');
        }
        catch (error) {
            dispatch({ type: 'UPDATE_TIER_FAILURE', error: error.message });
        }
    }
}

