const tier = {
    tiers: [],
    loading: false,
    error: null,
}

const Tier = (state = tier, action) => {
    switch (action.type) {
        case 'GET_TIERS_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'GET_TIERS_SUCCESS':
            return {
                ...state,
                loading: false,
                tiers: action.tiers
            };
        case 'GET_TIERS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case 'ADD_TIER_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'ADD_TIER_SUCCESS':
            return {
                ...state,
                loading: false,
                tiers: [...state.tiers, action.tier]
            };
        case 'ADD_TIER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case 'DELETE_TIER_REQUEST':
            return {
                ...state,
                loading: true
            };
        case 'DELETE_TIER_SUCCESS':
            return {
                ...state,
                loading: false,
                tiers: state.tiers.filter(tier => tier._id !== action.id)
            };
        case 'DELETE_TIER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            };
            case 'UPDATE_TIER_REQUEST':
                return {
                    ...state,
                    loading: true
                };
            case 'UPDATE_TIER_SUCCESS':
                return {
                    ...state,
                    loading: false,
                    tiers: state.tiers.map(tier => {
                        if (tier._id === action.tier._id) {
                            return action.tier
                        }
                        return tier
                    })
                };
                case 'UPDATE_TIER_FAILURE':
                    return {
                        ...state,
                        loading: false,
                        error: action.error
                    };
        default:
            return state;
    }
}

export default Tier;
