import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const { addTier, updateTier } = require('../actions/tierActions');

const TierForm = (props) => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;

  const { loading, tiers } = useSelector(state => state.Tier);
  const [tie, setTie] = useState({});
  const [Tier, setTier] = useState({
    tierName: '',
    percentage: '',
  });

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      var updatedTier = tiers.find(tier => tier.id == id);
      if (updatedTier) {
        setTie(updatedTier);
        setTier({
          tierName: updatedTier.tierName,
          percentage: updatedTier.percentage,
        });
      }
    }

  }, [id, tiers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTier({ ...Tier, [name]: value });
  };


  const handleAddTier = (e) => {
    e.preventDefault();
    if (Tier.tierName === '') {
      toast.error('Please enter Tier name');
      return;
    } else if (Tier.percentage === '') {
      toast.error('Please enter percentage');
      return;
    } else if (Tier.percentage !== '' && Tier.name !== '') {
      dispatch(addTier(Tier));
      redirect('/tier');
    }

  }

  const handleUpdateTier = (e) => {
    e.preventDefault();
    dispatch(updateTier(tie._id, Tier));
    redirect('/tier');
  }

  return (
    <>
      <div className="content-wrapper container-fluid">
        {
          loading ? (<PulseLoader />) : (
            <div className="row p-5 justify-content-center">
              <div className="col-md-6">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{FormName}</h5>
                  </div>
                  <div className="card-body">
                    <form  >
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Tier Name" name='tierName' value={Tier.tierName} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Percentage" name='percentage' value={Tier.percentage} onChange={handleInputChange} required={true} type='number' placeholder="Percentage here... like 5" />
                          </div>
                        </div>
                      </div>

                      {
                        Editable === 'true' ? <button type="submit" onClick={handleUpdateTier} className="btn btn-primary">
                          Update Tier
                        </button> : <button type="submit" onClick={handleAddTier} className="btn btn-primary">
                          Save
                        </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default TierForm