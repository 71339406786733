const order = {
  orders: [],
  loading: false,
  error: null,
};

const Order = (state = order, action) => {
  switch (action.type) {
    case "ORDERS_GET_ALL_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ORDERS_GET_ALL_SUCCESS":
      return {
        ...state,
        loading: false,
        orders: action.orders,
      };
    case "ORDERS_GET_ALL_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "LOAD_ORDERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "LOAD_ORDERS_SUCCESS":
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case "LOAD_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "UPDATE_ORDER_STATUS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_ORDER_STATUS_SUCCESS":
      return {
        ...state,
        loading: false,
        orders: state.orders.map((order) =>
          order.id === action.order.id ? action.order : order
        ),
      };
    case "UPDATE_ORDER_STATUS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Order;
